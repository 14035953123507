import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-toggle-switch',
  templateUrl: './toggle-switch.component.html',
  styleUrls: ['./toggle-switch.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToggleSwitchComponent {
  public label: string;
  @Input() public id: string;
  @Input() public name: string;
  @Input() public disabled: boolean;
  @Input() public nonClickableLabel: boolean;

  @Input() public value: boolean;
  @Output() public valueChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input() public switchButtonContainerTemplate: TemplateRef<any>;
  @Input() public switchContainerClass: string;

  constructor(private changeDetectorRef: ChangeDetectorRef) {
    this.switchContainerClass = 'd-flex align-items-center';
  }

  public onValueChanged(): void {
    this.value = !this.value;
    this.valueChange.emit(this.value);
    this.changeDetectorRef.detectChanges();
  }

  public get status(): string {
    return this.value === true ? 'label_on' : 'label_off';
  }
}
