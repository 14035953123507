<ng-template #switchButtonTemplate>
  <div class="d-flex align-items-center toggle-switch-btn-container">
    <button
      type="button"
      role="checkbox"
      [name]="name"
      [disabled]="disabled"
      [id]="name"
      [attr.aria-checked]="value"
      [attr.aria-labelledby]="name + 'Label'"
      class="toggle-switch-btn toggle-switch round"
      (click)="onValueChanged()"
    >
      <span class="toggle-switch-slider round" [attr.aria-hidden]="true"></span>
    </button>
    <span class="ms-2 me-2">{{ status | translate }}</span>
  </div>
</ng-template>

<ng-template #defaultSwitchButtonContainerTemplate let-switchButtonTemplate>
  <ng-template [ngTemplateOutlet]="switchButtonTemplate"> </ng-template>
</ng-template>

<div class="toggle-switch-container w-100" [ngClass]="switchContainerClass">
  <label class="me-3" [for]="name" [id]="name + 'Label'">
    <ng-content select=".switch-label"></ng-content>
  </label>
  <ng-template
    [ngTemplateOutlet]="switchButtonContainerTemplate ?? defaultSwitchButtonContainerTemplate"
    [ngTemplateOutletContext]="{ $implicit: switchButtonTemplate }"
  >
  </ng-template>
</div>
