import { IIssueTrackingProject, IProject } from '../../../shared/interfaces/project.interface';
import { SharedCommonUtility } from '../../../shared/utils/common.utility';
import { $project, ProjectStatus } from '../../../shared/constants/project';

export class ProjectUtility {
  public static getFormattedIntegrationName(project: IProject): string {
    const integrationProject: IIssueTrackingProject = project?.[$project.issueIntegrationProject];
    if (SharedCommonUtility.isNullish(integrationProject)) {
      return '';
    } else if (SharedCommonUtility.isNullishOrEmpty(integrationProject[$project.issueIntegrationProjectKey])) {
      return integrationProject[$project.issueIntegrationProjectName];
    }

    return `${integrationProject[$project.issueIntegrationProjectName]} (${
      integrationProject[$project.issueIntegrationProjectKey]
    })`;
  }

  /**
   * Creates a shallow copy of a filtered portion of a given projects array
   * @param projects projects array
   */
  public static getActiveProjects(projects: IProject[]): IProject[] {
    if (SharedCommonUtility.isNullishOrEmpty(projects)) {
      return [];
    }

    return projects.filter((project: IProject) => project[$project.status] === ProjectStatus.active);
  }
}
