import { Component, ViewChild, OnInit, AfterViewInit, ChangeDetectorRef, Input, TemplateRef } from '@angular/core';
import { Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  Icons,
  DsLinkColor,
  DsTooltipPosition,
  DsButtonVariants,
  DsButtonColor,
  DsModalService,
  DsModalVariant,
} from '@levelaccess/design-system';

import { RequiredSecurities } from '../../../../../shared/constants/required-securities';
import { Api } from '../../../../../shared/constants/api';
import { INavMenuItem, NavigationItem } from '../../../services/navigation/nav-structure';
import { NavService } from '../../../services/navigation/nav.service';
import { TranslateService } from '../../../translate/translate.service';
import { SharedCommonUtility } from '../../../../../shared/utils/common.utility';
import { TopNavbarWorkspacesDropdownComponent } from './top-navbar-workspaces-dropdown/top-navbar-workspaces-dropdown.component';
import { IWorkspaceClient } from '../../../../../shared/interfaces/workspace.interface';
import { $workspace } from '../../../../../shared/constants/workspace';
import { AclSecurityAdapter } from '../../../services/acl.service';
import { UserAclService } from '../../../services/user-acl.service';
import { AppConfigService } from '../../../services/app-config.service';
import { ExternalLinkService } from '../../../services/external-link.service';
import { FeatureFlagCollection } from '../../../../../shared/interfaces/feature-flag.interface';
import { FeatureFlagService } from '../../../services/feature-flag/feature-flag.service';

@Component({
  selector: 'compound-top-navbar',
  templateUrl: './compound-top-navbar.component.html',
  styleUrls: ['./compound-top-navbar.component.scss'],
})
export class CompoundTopNavbarComponent implements OnInit, AfterViewInit {
  private readonly maxNavTitleLength: number = 60;

  @ViewChild(TopNavbarWorkspacesDropdownComponent)
  private topNavbarWorkspacesDropdown: TopNavbarWorkspacesDropdownComponent;

  @ViewChild('mobileNavModal') public mobileNavModal: TemplateRef<any>;

  @Input() public showMobileNavVersion: boolean;

  public Icons: typeof Icons;
  public DsLinkColor: typeof DsLinkColor;
  public DsButtonVariants: typeof DsButtonVariants;
  public DsTooltipPosition: typeof DsTooltipPosition;
  public DsButtonColor: typeof DsButtonColor;
  public readonly FeatureFlagCollection: typeof FeatureFlagCollection = FeatureFlagCollection;

  public hasAccessToNotifications$: Observable<boolean>;
  public hasAccessToAdminOrganizations$: Observable<boolean>;
  public hasAccessToOrganization$: Observable<boolean>;
  public hasAccessToNewDashboard$: Observable<boolean>;
  public hasAccessToPortfolio$: Observable<boolean>;
  public logoUrl$: Observable<string>;

  public navTitle$: Observable<string>;
  public academyNavigationItem$: Observable<INavMenuItem>;
  public oldHelpNavigationItem$: Observable<INavMenuItem>;
  public helpNavigationItem$: Observable<INavMenuItem>;
  public serviceRequestNavigationItem$: Observable<INavMenuItem>;
  public organizationLink$: Observable<string>;
  public navSupportPortalUxEnabled$: Observable<boolean>;

  constructor(
    protected externalLinkService: ExternalLinkService,
    private navService: NavService,
    private translateService: TranslateService,
    private userAclService: UserAclService,
    private changeDetectorRef: ChangeDetectorRef,
    private appConfigService: AppConfigService,
    private dsModalService: DsModalService,
    private featureFlagService: FeatureFlagService,
  ) {
    this.Icons = Icons;
    this.DsLinkColor = DsLinkColor;
    this.DsButtonVariants = DsButtonVariants;
    this.DsTooltipPosition = DsTooltipPosition;
    this.DsButtonColor = DsButtonColor;

    this.hasAccessToNotifications$ = this.userAclService
      .createCheckAccessForCurrentUser()
      .pipe(
        map((adapter: AclSecurityAdapter): boolean =>
          adapter.useFunctionalActions(RequiredSecurities.Notifications_Read.functionalActions).check(),
        ),
      );

    this.hasAccessToAdminOrganizations$ = this.userAclService
      .createCheckAccessForCurrentUser()
      .pipe(
        map((adapter: AclSecurityAdapter): boolean =>
          adapter
            .useFunctionalActions(RequiredSecurities.Tenants_Read.functionalActions)
            .useOptions({ requireFunctionalActions: true })
            .useRequiredSecurity(RequiredSecurities.Tenants_Read, true)
            .check(),
        ),
      );

    this.hasAccessToNewDashboard$ = this.userAclService
      .createCheckAccessForCurrentUser()
      .pipe(
        map((adapter: AclSecurityAdapter): boolean =>
          adapter
            .useFunctionalActions(RequiredSecurities.GR_Governance_Read.functionalActions)
            .useOptions({ requireFunctionalActions: true })
            .useRequiredSecurity(RequiredSecurities.GR_Governance_Read, false)
            .check(),
        ),
      );

    this.hasAccessToPortfolio$ = this.userAclService
      .createCheckAccessForCurrentUser()
      .pipe(
        map((adapter: AclSecurityAdapter): boolean =>
          adapter
            .useFunctionalActions(RequiredSecurities.Workspaces_Read_Digital_Properties_Read.functionalActions)
            .useOptions({ requireFunctionalActions: true })
            .useRequiredSecurity(RequiredSecurities.Workspaces_Read_Digital_Properties_Read, false)
            .check(),
        ),
      );

    this.logoUrl$ = combineLatest([
      this.hasAccessToAdminOrganizations$,
      this.hasAccessToNewDashboard$,
      this.hasAccessToPortfolio$,
    ]).pipe(
      map(
        ([hasAccessToAdminOrganizations, hasAccessToNewDashboard, hasAccessToPortfolio]: [boolean, boolean, boolean]): string => {
          if (!this.appConfigService.isTenantEnabled()) {
            if (hasAccessToAdminOrganizations) {
              return `/${Api.admin}/${Api.organizations}`;
            }
            return `/${Api.settings}/${Api.profile}`;
          }

          if (hasAccessToNewDashboard) {
            return `/${Api.overview}`;
          }

          if (hasAccessToPortfolio) {
            return `/${Api.portfolio}`;
          }

          return `/${Api.dashboard}`;
        },
      ),
    );
  }

  private setupOrganizationPermission(): void {
    this.hasAccessToOrganization$ = this.userAclService
      .createCheckAccessForCurrentUser()
      .pipe(
        map((adapter: AclSecurityAdapter): boolean =>
          adapter
            .useTenantFromUser()
            .useFunctionalActions(RequiredSecurities.GR_Organization_Dashboard_Portfolio_Read.functionalActions)
            .check(),
        ),
      );
  }

  private initNavTitle(): void {
    const selectedWorkspace$: Observable<IWorkspaceClient> = ((): Observable<IWorkspaceClient> => {
      /**
       * Since the parent of the TopNavbarWorkspacesDropdownComponent is conditioned by a *ngIf,
       * running the changes detection is necessary whenever the showMobileNavVersion changes from true to false.
       */
      if (!this.topNavbarWorkspacesDropdown) {
        this.changeDetectorRef.detectChanges();
      }

      return combineLatest([
        this.topNavbarWorkspacesDropdown.workspaces$,
        this.topNavbarWorkspacesDropdown.selectedWorkspaceId$,
      ]).pipe(
        map(
          ([workspaces, selectedWorkspaceId]: [IWorkspaceClient[], string]): IWorkspaceClient =>
            workspaces.find((workspace: IWorkspaceClient): boolean => workspace[$workspace._id] === selectedWorkspaceId),
        ),
      );
    })();

    this.navTitle$ = combineLatest([selectedWorkspace$, this.navService.activeTopLevelMenuItem$()]).pipe(
      map(([selectedWorkspace, activeMenuItem]: [IWorkspaceClient, INavMenuItem]): string | undefined => {
        if (SharedCommonUtility.notNullishOrEmpty(activeMenuItem?.navTitle)) {
          return this.translateService.instant(activeMenuItem.navTitle);
        }

        if (activeMenuItem?.id === NavigationItem.workspace && SharedCommonUtility.notNullish(selectedWorkspace)) {
          return selectedWorkspace[$workspace.name];
        }

        return undefined;
      }),
      map((title: string | undefined): string | undefined => {
        if (SharedCommonUtility.isNullishOrEmpty(title)) {
          return undefined;
        }

        if (title.length > this.maxNavTitleLength) {
          return `${title.substring(0, this.maxNavTitleLength)}...`;
        }

        return title;
      }),
    );
  }

  public openMobileMenu(): void {
    this.dsModalService.open(this.mobileNavModal, DsModalVariant.fullScreen);
  }

  public ngOnInit(): void {
    this.academyNavigationItem$ = this.navService
      .getTopLevelMenuItems$([NavigationItem.academy])
      .pipe(map((menuItems: INavMenuItem[]): INavMenuItem => menuItems?.filter((item: INavMenuItem) => item?.isAvailable)?.[0]));
    this.oldHelpNavigationItem$ = this.navService
      .getTopLevelMenuItems$([NavigationItem.old_help])
      .pipe(map((menuItems: INavMenuItem[]): INavMenuItem => menuItems?.[0]));
    this.helpNavigationItem$ = this.navService
      .getTopLevelMenuItems$([NavigationItem.help])
      .pipe(map((menuItems: INavMenuItem[]): INavMenuItem => menuItems?.[0]));
    this.serviceRequestNavigationItem$ = this.navService
      .getTopLevelMenuItems$([NavigationItem.service_request])
      .pipe(map((menuItems: INavMenuItem[]): INavMenuItem => menuItems?.[0]));
    this.organizationLink$ = this.navService
      .getTopLevelMenuItems$([NavigationItem.organization_dashboard])
      .pipe(map((menuItems: INavMenuItem[]) => menuItems?.[0]?.routerLink || `/${Api.organization}`));

    this.navSupportPortalUxEnabled$ = this.featureFlagService
      .variation$(FeatureFlagCollection.navSupportPortalUx, false, true)
      .pipe(map(Boolean));
  }

  public ngAfterViewInit(): void {
    if (!this.showMobileNavVersion) {
      this.initNavTitle();
    }

    this.setupOrganizationPermission();
    this.changeDetectorRef.detectChanges();
  }
}
