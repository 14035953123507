<ds-modal [modal]="modal" [title]="title" (modalClosed)="onClose()">
  <p  class="message" *ngIf="message">{{ message }}</p>
  <p  *ngIf="description">{{ description }}</p>

  <ds-button-group
    [primaryAction]="{ label: confirmLabel || 'label_confirm' | translate }"
    [secondAction]="{ label: 'form_cancel' | translate, variant: hideCancelButton ? null : DsButtonVariants.secondary }"
    (primaryActionClicked)="onConfirm()"
    (secondActionClicked)="onCancel()"
  >
  </ds-button-group>
</ds-modal>
