import { IComponentResult } from './component-result.interface';

export enum RuleTestType {
  automatic = 'Automatic',
  guided_automatic = 'Guided Automatic',
  advisory = 'Advisory',
  custom = 'Custom',
}

export enum AccessEngineResultTestType {
  global = 'GLOBAL',
  implied_global = 'IMPLIED_GLOBAL',
  automatic = 'AUTOMATIC',
  guided_automatic = 'GUIDED_AUTOMATIC',
  manual = 'MANUAL',
  guided_manual = 'GUIDED_MANUAL',
  advisory = 'ADVISORY',
}

export enum AccessEngineTestRequestType {
  global = 2,
  implied_global = 3,
  automatic = 4,
  guided_automatic = 5,
  manual = 6,
  guided_manual = 30,
  advisory = 31,
}

export enum AccessEngineTestOutcome {
  fail = 'fail',
  pass = 'pass',
  na = 'na',
}

export type IAccessEngineAuditResults = IAccessEngineAuditResult[];

export interface IAccessEngineAuditResult {
  testId: string;
  testType: AccessEngineResultTestType;
  outcome: AccessEngineTestOutcome;
  results: IAccessEngineTestResultItem[];
}

export interface IAccessEngineTestResultItem {
  attNo: number;
  css: string;
  encoding: number[];
  uel: string;
  clusteringData: IAccessEngineClusteringData;
  html?: string;
  componentData?: IComponentResult;
}

export interface IAccessEngineMobileAuditResult extends Omit<IAccessEngineAuditResult, 'results'> {
  results: IAccessEngineMobileTestResultItem[];
}

export interface IAccessEngineMobileTestResultItem {
  uel: string;
  element: string;
  timestamp: string;
}

export interface IAccessEngineClusteringData {
  uniqueId: string;
  tagName: string;
  attributes: Record<string, string>;
  text: string;
  children: IAccessEngineClusteringData[];
}

export interface IAccessEngine {
  getVersion(): string;
  nextgen_runAllTests_returnInstances_JSON(types: AccessEngineTestRequestType[]): string;
  nextgen_runAllTests_Android_returnInstances_JSON(types: AccessEngineTestRequestType[]): string;
  nextgen_runAllTests_iOS_returnInstances_JSON(types: AccessEngineTestRequestType[]): string;
}
