import { CommonModule } from '@angular/common';
import { NgModule, Type } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DsModule } from '@levelaccess/design-system';

import { FilterPipe } from '../pipes/filter.pipe';
import { TranslateHtmlPipe } from '../translate/translate-html.pipe';
import { SanitizerPipe } from '../pipes/sanitizer.pipe';
import { DateTimePipe } from '../pipes/date-time.pipe';
import { HighlightDirective } from '../directives/highlight/highlight.directive';
import { ArrayReversePipe } from '../pipes/array-reverse.pipe';
import { ArrayToStringPipe } from '../pipes/array-to-string.pipe';
import { ViewportBoxComponent } from '../components/viewport-box/viewport-box.component';
import { FieldInvalidPipe } from '../pipes/field-invalid.pipe';
import { KeysPipe } from '../pipes/keys.pipe';
import { LinkifyHtmlPipe } from '../pipes/linkify-html.pipe';
import { LinkifyPipe } from '../pipes/linkify.pipe';
import { PrettifyDatePipe } from '../pipes/prettify-date.pipe';
import { EllipsisPipe } from '../pipes/ellipsis.pipe';

import { NotificationsComponent } from '../components/notifications/notifications.component';
import { TreeComponent } from '../components/tree/tree.component';
import { AlertComponent } from '../components/alert/alert.component';
import { FormFieldErrorComponent } from '../components/form-field-error/form-field-error.component';
import { FormErrorMessagesComponent } from '../components/form-error-messages/form-error-messages.component';
import { ErrorMessageComponent } from '../components/error-message/error-message.component';
import { CropImageComponent } from '../components/crop-image/crop-image.component';
import { TooltipComponent } from '../directives/tooltip/tooltip.component';
import { TypeaheadComponent } from '../components/typeahead/typeahead.component';
import { RuleSeverityComponent } from '../components/rule-severity/rule-severity.component';

import { LazyImageDirective } from '../directives/lazy-image/lazy-image.directive';
import { CollapseDirective } from '../directives/collapse/collapse.directive';
import { NativeElementInjectorDirective } from '../directives/form-native-element/form-native-element.directive';
import { NoDoubleActivateDirective } from '../directives/no-double-activate/no-double-activate.directive';
import { InputOnlyNumbersDirective } from '../directives/input-only-numbers/input-only-numbers.directive';
import { TooltipDirective } from '../directives/tooltip/tooltip.directive';
import { TabsDirective } from '../directives/tabs/tabs.directive';
import { FormElementStateDirective } from '../directives/form-element-state/form-element-state.directive';
import { CloseActionMenuDirective } from '../directives/close-action-menu/close-action-menu.directive';
import { DownloadLinkDirective } from '../directives/download-link/download-link.directive';
import { TranslatedLinksDirective } from '../directives/translated-links/translated-links.directive';
import { CopyToClipboardComponent } from '../components/copy-to-clipboard/copy-to-clipboard.component';
import { ScanActionsComponent } from '../components/scan-actions/scan-actions.component';
import { FileInputComponent } from '../components/file-input/file-input.component';
import { PaginationEntriesPerPageComponent } from '../components/pagination/entries-per-page/pagination-entries-per-page.component';
import { DatetimeLocalComponent } from '../components/form-field/datetime-local/datetime-local.component';
import { ModalContainerComponent } from '../components/modals/modal-container.component';
import { CustomTableComponent } from '../components/table/custom-table/custom-table.component';
import { CustomFileInputComponent } from '../components/file-input/custom-file-input/custom-file-input.component';
import { PaginationArrowsNavigationComponent } from '../components/pagination/arrows-navigation/pagination-arrows-navigation.component';
import { DigitalPropertyWorkspaceFilterComponent } from '../components/digital-property-workspace-filter/digital-property-workspace-filter.component';
import { FilteringInputComponent } from '../components/filtering/input/filtering-input.component';
import { TableEmptyStateComponent } from '../components/table/custom-table/empty-state/table-empty-state.component';
import { TabsComponent } from '../components/tabs/tabs.component';
import { ScanScoreCircleComponent } from '../components/scan-score-circle/scan-score-circle.component';
import { HasPermissionsDirective } from '../directives/has-permissions/has-permissions.directive';
import { CommonInputComponent } from '../components/common-input/common-input.component';
import { CommonInputInPlaceEditorComponent } from '../components/common-input-in-place-editor/common-input-in-place-editor.component';
import { CommonTextareaComponent } from '../components/common-textarea/common-textarea.component';
import { CommonCheckboxComponent } from '../components/common-checkbox/common-checkbox.component';
import { CommonSelectComponent } from '../components/common-select/common-select.component';
import { AccordionItemComponent } from '../components/common-accordion-item/accordion-item.component';
import { SortSelectorComponent } from '../components/sort-selector/sort-selector.component';
import { ViewportCustomValuesComponent } from '../components/viewport-custom-values/viewport-custom-values.component';
import { LabelValueComponent } from '../components/label-value/label-value.component';
import { ScanAuthenticationDetailsComponent } from '../components/scan-authentication-details/scan-authentication-details.component';
import { DomainRestrictionsComponent } from '../components/domain-restrictions/domain-restrictions.component';
import { ScanExtraHeadersComponent } from '../components/scan-extra-headers/scan-extra-headers.component';
import { MonitoringAlertIconComponent } from '../components/monitoring-alert-icon/monitoring-alert-icon.component';
import { MonitoringLabelComponent } from '../components/monitoring-label/monitoring-label.component';
import { RuleStatusToggleComponent } from '../components/rule-status-toggle/rule-status-toggle.component';
import { ScanTagSelectorComponent } from '../components/scan-tag-selector/scan-tag-selector.component';
import { FilterKeywordComponent } from '../components/filtering/keyword/filter-keyword.component';
import { FilterCheckboxComponent } from '../components/filtering/checkbox/filter-checkbox/filter-checkbox.component';
import { FilterHostDirective } from '../directives/filter-host/filter-host.directive';
import { ScanTagFilterComponent } from '../components/scan-tag-filter/scan-tag-filter.component';
import { AvatarInputComponent } from '../components/avatar/avatar-input/avatar-input.component';
import { EditUserComponent } from '../components/users/edit-user/edit-user.component';
import { StatusComponent } from '../components/status/status.component';
import { DownloadReportNewComponent } from '../components/download-report-new/download-report.component';
import { ExportReportButtonComponent } from '../components/export-report-button/export-report-button.component';
import { MultiselectTypeaheadComponent } from '../components/multiselect-typeahead/multiselect-typeahead.component';
import { NgbTableComponent } from '../components/table/ngb-table/ngb-table.component';
import { DclWrapperComponent } from '../components/table/ngb-table/utilities/dcl-wrapper.component';
import { NgbTableEmptyStateComponent } from '../components/table/ngb-table/empty-state/table-empty-state.component';
import { ViewportInlineComponent } from '../components/viewport-inline/viewport-inline.component';
import { ViewSingularRuleHistoryComponent } from '../components/view-rule-history/singular/view-singular-rule-history.component';
import { ViewRuleHistoryItemComponent } from '../components/view-rule-history/view-rule-history-item/view-rule-history-item.component';
import { RuleIssuesTableComponent } from '../components/rule-issues-table/rule-issues-table.component';
import { MonitoringTrendModalComponent } from '../components/dashboard/monitoring-trend/monitoring-trend-modal/monitoring-trend-modal.component';
import { ScanAnalyticsHealthGraphComponent } from '../components/scan-analytics-health-graph/scan-analytics-health-graph.component';
import { ScanHealthChartComponent } from '../components/scan-health-chart/scan-health-chart.component';
import { CommonDragAndDropFilesComponent } from '../components/common-drag-and-drop-files/common-drag-and-drop-files.component';
import { CommonCommentComponent } from '../components/common-comment/common-comment.component';
import { HorizontalStepperComponent } from '../components/stepper/horizontal-stepper/horizontal-stepper.component';
import { StepComponent } from '../components/stepper/step/step.component';
import { StepHeaderComponent } from '../components/stepper/step-header/step-header.component';
import { StepContentDirective } from '../components/stepper/step/step-content.directive';
import { StepNextDirective } from '../components/stepper/directives/step-next.directive';
import { StepPreviousDirective } from '../components/stepper/directives/step-previous.directive';
import { UpsertTaskModalComponent } from '../pages/public/projects/tasks/upsert-task-modal/upsert-task-modal.component';
import { CommonConfirmationComponent } from '../components/common-confirmation-modal/common-confirmation.component';
import { MarkdownEditorComponent } from '../components/markdown-editor/markdown-editor.component';
import { MarkdownViewComponent } from '../components/markdown-view/markdown-view.component';
import { AiChatMarkdownViewComponent } from '../components/ai-chat-markdown-view/ai-chat-markdown-view.component';
import { ViewAvatarComponent } from '../components/avatar/view/view-avatar.component';
import { IssuesViewDetailsComponent } from '../pages/public/scans/scan-results/issues-view/issues-view-details/issues-view-details.component';
import { SearchboxComponent } from '../components/searchbox/searchbox.component';
import { PageLoadingStateComponent } from '../components/page-loading-state/page-loading-state.component';
import { ViewFlawStatusHistoryComponent } from '../components/view-flaw-status-history/view-flaw-status-history.component';
import { ViewFlawStatusHistoryItemComponent } from '../components/view-flaw-status-history/view-flaw-status-history-item/view-flaw-status-history-item.component';
import { AttachmentThumbnailComponent } from '../components/attachment-thumbnail/attachment-thumbnail.component';
import { CheckboxConfirmationModalComponent } from '../components/modals/checkbox-confirmation-modal/checkbox-confirmation-modal.component';
import { PromptConfirmationModalComponent } from '../components/modals/prompt-confirmation-modal/prompt-confirmation-modal.component';
import { AlertModalComponent } from '../components/modals/alert-modal/alert-modal.component';
import { ShareIssueReportModalComponent } from '../components/share-issue-report-modal/share-issue-report-modal.component';
import { ShareIssueReportUrlComponent } from '../components/share-issue-report-url/share-issue-report-url.component';
import { FileDropDirective } from '../directives/file-dropbox/fileDropbox.directive';
import { CommonNameTagComponent } from '../components/common-name-tag/common-name-tag.component';
import { CommonFileDropboxComponent } from '../components/common-file-dropbox/common-file-dropbox.component';
import { AdvancedSearchComponent } from '../components/advanced-search/advanced-search.component';
import { CommonSwitchComponent } from '../components/common-switch/common-switch.component';
import { ResponsivePaginationComponent } from '../components/pagination/responsive-pagination/responsive-pagination.component';
import { SvgIconsComponent } from './svg-icons.component';
import { GlobalSpinnerComponent } from '../components/global-spinner/global-spinner.component';
import { CommonInfoNoteComponent } from '../components/common-info-note/common-info-note.component';
import { FormFieldCharCountComponent } from '../components/form-field-char-count/form-field-char-count.component';
import { WorkspaceUsersListComponent } from '../components/workspace/users-list/users-list.component';
import { TableColumnSelectorComponent } from '../components/table-column-selector/table-column-selector.component';
import { TableColumnSelectorPanelComponent } from '../components/table-column-selector/table-column-selector-panel/table-column-selector-panel.component';
import { RouteWrapperComponent } from '../components/route-wrapper/route-wrapper.component';
import { FormFieldLineCountComponent } from '../components/form-field-line-count/form-field-line-count.component';
import { ViewportSelectComponent } from '../components/viewport-select/viewport-select.component';
import { TypeaheadDirective } from '../directives/typeahead/typeahead.directive';
import { CommonAlertBoxComponent } from '../components/common-alert-box/common-alert-box.component';
import { CommonMultiSelectComponent } from '../components/common-multi-select/common-multi-select.component';
import { DateFieldComponent } from '../components/form-field/date-field/date-field.component';
import {
  AccordionButtonDirective,
  AccordionChevronComponent,
  AccordionComponent,
  AccordionPanelContentDirective,
  AccordionPanelDirective,
  AccordionPanelHeaderDirective,
  AccordionPanelToggleDirective,
} from '../components/common-accordion/accordion.component';
import { ImageHighlighterComponent } from '../components/image-highlighter/image-highlighter.component';
import { UserMfaComponent } from '../components/user-mfa/user-mfa.component';
import { FileDragAndDropComponent } from '../components/form-field/file-upload/file-drag-and-drop/file-drag-and-drop.component';
import { InfoModalComponent } from '../components/modals/info-modal/info-modal.component';
import { SecureResourceIdDirective } from '../components/image/secure-resource-id.directive';
import { BytesToReadableUnitsPipe } from '../pipes/bytes-to-readable-units.pipe';
import { LanguageNamePipe } from '../pipes/language-name.pipe';
import { CommonCheckboxSelectComponent } from '../components/common-checkbox-select/common-checkbox-select.component';
import { CommonSpinnerComponent } from '../components/common-spinner/common-spinner.component';
import { CommonCheckboxTreeComponent } from '../components/common-checkbox-tree/common-checkbox-tree.component';
import { CheckboxTreeNodeComponent } from '../components/common-checkbox-tree/checkbox-tree-node/checkbox-tree-node.component';
import { FocusDirective } from '../directives/focus/focus.directive';
import { TestingToolFilterComponent } from '../components/testing-tool-filter/testing-tool-filter.component';
import { TurnOffMonitoringConfirmationComponent } from '../components/digital-property-monitoring/turn-off-monitoring-confirmation/turn-off-monitoring-confirmation.component';
import { MonitoringQuickSetupComponent } from '../components/digital-property-monitoring/quick-setup/monitoring-quick-setup.component';
import { MonitoringScopeStepComponent } from '../components/digital-property-monitoring/advanced-setup/steps/monitoring-scope-step/monitoring-scope-step.component';
import { ExcludePagesComponent } from '../components/exclude-pages/exclude-pages.component';
import { MonitoringScheduleStepComponent } from '../components/digital-property-monitoring/advanced-setup/steps/monitoring-schedule-step/monitoring-schedule-step.component';
import { MonitoringScanSettingsStepComponent } from '../components/digital-property-monitoring/advanced-setup/steps/monitoring-scan-settings-step/monitoring-scan-settings-step.component';
import { EapIconsComponent } from './eap-icons.component';
import { IconComponent } from '../components/icon/icon.component';
import { MonitoringAlertsStepComponent } from '../components/digital-property-monitoring/advanced-setup/steps/monitoring-alerts-step/monitoring-alerts-step.component';
import { MonitoringAlertsEditorComponent } from '../components/digital-property-monitoring/advanced-setup/steps/monitoring-alerts-step/monitoring-alerts-editor/monitoring-alerts-editor.component';
import { MonitoringReviewStepComponent } from '../components/digital-property-monitoring/advanced-setup/steps/monitoring-review-step/monitoring-review-step.component';
import { TenantSelectorComponent } from '../components/tenant-selector/tenant-selector.component';
import { AriaSortDirective } from '../directives/aria-sort/aria-sort.directive';
import { AriaLabelSortDirective } from '../directives/aria-label-sort/aria-label-sort.directive';
import { MonitoringAlertTableComponent } from '../components/monitoring-alert-table/monitoring-alert-table.component';
import { CommonLinkComponent } from '../components/common-link/common-link.component';
import { CtrlKeyDirective } from '../directives/ctrl-key/ctrl-key.directive';
import { FilterNumberComponent } from '../components/filtering/number/filter-number.component';
import { ResolvedFindingsProgressComponent } from '../components/resolved-findings-progress/resolved-findings-progress.component';
import { RuleIssuesSupplementaryTableComponent } from '../components/rule-issues-table/rule-issues-supplementary-table.component';
import { ProgressDoughnutComponent } from '../components/progress-doughnut/progress-doughnut.component';
import { EditAttachmentModalComponent } from '../components/attachment-thumbnail/edit-attachment/edit-attachment-modal.component';
import { FocusFirstFocusableElementDirective } from '../directives/focus-first-focusable-element/focus-first-focusable-element.directive';
import { NgbNavDirective } from '../directives/ngb-nav/ngb-nav.directive';
import { CommonTrendComponent } from '../components/common-trend/common-trend.component';
import { TrapFocusDirective } from '../directives/trap-focus/trap-focus.directive';
import { AccordionFilterComponent } from '../components/filtering/accordion-filter/accordion-filter.component';
import { DropdownCellComponent } from '../components/table/ngb-table/cells/dropdown-cell/dropdown-cell.component';
import { DocumentFilesCellComponent } from '../components/table/ngb-table/cells/document-files-cell/document-files-cell.component';
import { EmptyCellComponent } from '../components/table/ngb-table/cells/empty-cell/empty-cell.component';
import { AttachmentsCellComponent } from '../components/table/ngb-table/cells/attachments-cell/attachments-cell.component';
import { ButtonCellComponent } from '../components/table/ngb-table/cells/button-cell/button-cell.component';
import { CheckboxCellComponent } from '../components/table/ngb-table/cells/checkbox-cell/checkbox-cell.component';
import { DataPointCellComponent } from '../components/table/ngb-table/cells/data-point-cell/data-point-cell.component';
import { ComposedCellComponent } from '../components/table/ngb-table/cells/composed-cell/composed-cell.component';
import { ExternalLinkButtonCellComponent } from '../components/table/ngb-table/cells/external-link-button-cell/external-link-button-cell.component';
import { ExternalLinkCellComponent } from '../components/table/ngb-table/cells/external-link-cell/external-link-cell.component';
import { HtmlCellComponent } from '../components/table/ngb-table/cells/html-cell/html-cell.component';
import { HtmlHighlightCellComponent } from '../components/table/ngb-table/cells/html-highlight-cell/html-highlight-cell.component';
import { IssueStateLabelCellComponent } from '../components/table/ngb-table/cells/issue-state-label-cell/issue-state-label-cell.component';
import { IssuesViewDetailsCellComponent } from '../components/table/ngb-table/cells/issues-view-details-cell/issue-view-details-cell.component';
import { LinkCellComponent } from '../components/table/ngb-table/cells/link-cell/link-cell.component';
import { LinksOrderedListCellComponent } from '../components/table/ngb-table/cells/links-ordered-list-cell/links-ordered-list-cell.component';
import { OrderedListCellComponent } from '../components/table/ngb-table/cells/ordered-list-cell/ordered-list-cell.component';
import { PendingCellComponent } from '../components/table/ngb-table/cells/pending-cell/pending-cell.component';
import { RuleStatusToggleCellComponent } from '../components/table/ngb-table/cells/rule-status-toggle-cell/rule-status-toggle-cell.component';
import { ScoreCellComponent } from '../components/table/ngb-table/cells/score-cell/score-cell.component';
import { SecureImageCellComponent } from '../components/table/ngb-table/cells/secure-image-cell/secure-image-cell.component';
import { SelectCellComponent } from '../components/table/ngb-table/cells/select-cell/select-cell.component';
import { SeverityCellComponent } from '../components/table/ngb-table/cells/severity-cell/severity-cell.component';
import { TemplateCellComponent } from '../components/table/ngb-table/cells/template-cell/template-cell.component';
import { TextCellComponent } from '../components/table/ngb-table/cells/text-cell/text-cell.component';
import { TextWithIconCellComponent } from '../components/table/ngb-table/cells/text-with-icon-cell/text-with-icon-cell.component';
import { ThumbnailCellComponent } from '../components/table/ngb-table/cells/thumbnail-cell/thumbnail-cell.component';
import { TitleCellComponent } from '../components/table/ngb-table/cells/title-cell/title-cell.component';
import { TypeAheadCellComponent } from '../components/table/ngb-table/cells/type-ahead-cell/type-ahead-cell.component';
import { TooltipCellComponent } from '../components/table/ngb-table/cells/tooltip-cell/tooltip-cell.component';
import { UserAccountStatusCellComponent } from '../components/table/ngb-table/cells/user-account-status-cell/user-account-status-cell.component';
import { BaseCellComponent } from '../components/table/ngb-table/cells/base-cell/base-cell.component';
import { RuleFilterComponent } from '../components/rule-filter/rule-filter.component';
import { CommonPipesModule } from './common-pipes.module';
import { FeatureFlagDirective } from '../directives/feature-flag/feature-flag.directive';
import { OverdueUnresolvedFindingsBySeverityComponent } from '../components/overdue-unresolved-findings-by-severity/overdue-unresolved-findings-by-severity.component';
import { DeprecatedScanTagSelectorComponent } from '../components/deprecated-scan-tag-selector/deprecated-scan-tag-selector.component';
import { AccessibilityConformanceLevelSelectorComponent } from '../components/accessibility-conformance-level-selector/accessibility-conformance-level-selector.component';
import { CodeBlockComponent } from '../components/code-block/code-block.component';
import { SecureAlertComponent } from '../components/secure-alert/secure-alert.component';
import { CompactNumberPipe } from '../pipes/compact-number.pipe';
import { NewNavCardDirective } from '../directives/new-nav-card/new-nav-card.directive';
import { TranslateCutPipe } from '../pipes/translate-cut.pipe';
import { RiskScoreExplanationModalComponent } from '../components/dashboard/risk-score/risk-score-explanation-modal/risk-score-explanation-modal.component';
import { WebsitesAppsByRiskManagementStatusComponent } from '../components/websites-apps-by-risk-management-status/websites-apps-by-risk-management-status.component';
import { WebsitesAppsByRiskManagementStatusChartComponent } from '../components/websites-apps-by-risk-management-status/websites-apps-by-risk-management-chart/websites-apps-by-risk-managment-chart.component';
import { WebsitesAppsByRiskManagementStatusTableModalComponent } from '../components/websites-apps-by-risk-management-status/websites-apps-by-risk-management-chart/websites-apps-by-risk-management-table-modal/websites-apps-by-risk-management-table-modal.component';
import { WebsitesAppsByRiskManagementStatusDetailsComponent } from '../components/websites-apps-by-risk-management-status/websites-apps-by-risk-management-status-details/websites-apps-by-risk-management-status-details.component';
import { OrganizationPermissionsTableComponent } from '../components/users/edit-user/organization-permissions-table/organization-permissions-table.component';
import { WorkspacePermissionsTableComponent } from '../components/users/edit-user/workspace-permissions-table/workspace-permissions-table.component';
import { EditPermissionsBannerComponent } from '../components/users/edit-user/edit-permissions-banner/edit-permissions-banner.component';
import { DigitalPropertyPermissionsTableComponent } from '../components/users/edit-user/digital-property-permissions-table/digital-property-permissions-table.component';
import { ConnectionErrorModalComponent } from '../components/issue-tracking/edit-connection/connection-error-modal/connection-error-modal.component';
import { AddPageModalComponent } from '../pages/public/pages/upsert-page/add-page-modal/add-page-modal.component';
import { ToolTemplateComponent } from '../pages/public/integrations/test-automation/tool-template/tool-template';
import { UpsertTaskSlideoutComponent } from '../pages/public/projects/tasks/upsert-task-slideout/upsert-task-slideout.component';
import { AuditRuleTableComponent } from '../components/audit-rule/audit-rule-table/audit-rule-table.component';
import { AuditRuleViewComponent } from '../components/audit-rule/audit-rule-view/audit-rule-view.component';
import { UpsertAuditRuleFormComponent } from '../components/audit-rule/upsert-audit-rule-form/upsert-audit-rule-form.component';
import { UpsertAuditRuleComponent } from '../components/audit-rule/upsert-audit-rule/upsert-audit-rule.component';
import { AuditDeleteRuleModalContentComponent } from '../components/audit-rule/audit-delete-rule-modal-content/audit-delete-rule-modal-content.component';
import { CreateProjectSlideoutComponent } from '../pages/public/projects/create-project-slideout/create-project-slideout.component';
import { BannerComponent } from '../components/banner/banner.component';
import { DSButtonCellComponent } from '../components/table/ngb-table/cells/ds-button-cell/ds-button-cell.component';
import { RiskManagementStatusStepperComponent } from '../components/dashboard/risk-score/risk-management-status-stepper/risk-management-status-stepper.component';
import { DisconnectedTasksModalComponent } from '../components/issue-tracking/edit-connection/disconnected-tasks-modal/disconnected-tasks-modal.component';
import { FilterSelectComponent } from '../components/filtering/select/filter-select.component';
import { DsConfirmationModalComponent } from '../components/ds-confirmation-modal/ds-confirmation-modal.component';
import { AdminPortalPermissionsTableComponent } from '../components/users/edit-user/admin-portal-permissions-table/admin-portal-permissions-table.component';
import { IsNullishPipe } from '../pipes/is-nullish.pipe';
import { DashboardUserPreferencesSlideoutComponent } from '../pages/public/organization/program-dashboard/user-preferences-slideout/user-preferences-slideout.component';
import { HandlePopupsComponent } from '../pages/public/automated-scan/advanced/scan-scope/handle-popups/handle-popups.component';
import { ScoreChartComponent } from '../components/score-chart/score-chart.component';
import { BreadcrumbWrapperComponent } from '../components/breadcrumb-wrapper/breadcrumb-wrapper.component';
import { DSChipCellComponent } from '../components/table/ngb-table/cells/ds-chip-cell/ds-chip-cell.component';
import { DsSeverityCellComponent } from '../components/table/ngb-table/cells/ds-severity-cell/ds-severity-cell.component';
import { ScanPreScanFlowComponent } from '../components/scan-pre-scan-flow/scan-pre-scan-flow.component';
import { TranslateTemplatesDirective } from '../directives/translate-templates/translate-templates.directive';
import { WaitTimeComponent } from '../pages/public/automated-scan/advanced/scan-scope/wait-time/wait-time.component';
import { CustomRuleSeverityComponent } from '../components/custom-rule-severity/custom-rule-severity.component';
import { ToggleSwitchComponent } from '../components/toggle-switch/toggle-switch.component';

const tableComponents: Type<any>[] = [
  NgbTableComponent,
  DclWrapperComponent,
  NgbTableEmptyStateComponent,
  BaseCellComponent,
  ButtonCellComponent,
  DSButtonCellComponent,
  DSChipCellComponent,
  DsSeverityCellComponent,
  HtmlCellComponent,
  TitleCellComponent,
  HtmlHighlightCellComponent,
  CheckboxCellComponent,
  IssueStateLabelCellComponent,
  DropdownCellComponent,
  LinkCellComponent,
  SeverityCellComponent,
  TextCellComponent,
  ExternalLinkCellComponent,
  ScoreCellComponent,
  DataPointCellComponent,
  ComposedCellComponent,
  IssuesViewDetailsCellComponent,
  ExternalLinkButtonCellComponent,
  PendingCellComponent,
  AttachmentsCellComponent,
  ThumbnailCellComponent,
  OrderedListCellComponent,
  LinksOrderedListCellComponent,
  UserAccountStatusCellComponent,
  TemplateCellComponent,
  SecureImageCellComponent,
  DocumentFilesCellComponent,
  TextWithIconCellComponent,
  SelectCellComponent,
  TypeAheadCellComponent,
  TooltipCellComponent,
  EmptyCellComponent,
  RuleStatusToggleCellComponent,
];

const stepper: Type<any>[] = [
  HorizontalStepperComponent,
  StepComponent,
  StepHeaderComponent,
  StepContentDirective,
  StepNextDirective,
  StepPreviousDirective,
];

const pipes: Type<any>[] = [
  TranslateHtmlPipe,
  SanitizerPipe,
  FilterPipe,
  DateTimePipe,
  ArrayReversePipe,
  ArrayToStringPipe,
  FieldInvalidPipe,
  KeysPipe,
  LinkifyHtmlPipe,
  LinkifyPipe,
  PrettifyDatePipe,
  EllipsisPipe,
  BytesToReadableUnitsPipe,
  CompactNumberPipe,
  TranslateCutPipe,
  LanguageNamePipe,
  IsNullishPipe,
];

const formComponents: Type<any>[] = [
  FileDragAndDropComponent,
  FormFieldErrorComponent,
  FileInputComponent,
  CustomFileInputComponent,
  CommonInputComponent,
  CommonInputInPlaceEditorComponent,
  CommonLinkComponent,
  CommonFileDropboxComponent,
  CommonTextareaComponent,
  CommonCheckboxSelectComponent,
  MultiselectTypeaheadComponent,
  CommonDragAndDropFilesComponent,
  MarkdownEditorComponent,
  SearchboxComponent,
  FormFieldCharCountComponent,
];

const sharedComponents: Type<any>[] = [
  ModalContainerComponent,
  LazyImageDirective,
  CollapseDirective,
  FileDropDirective,
  NativeElementInjectorDirective,
  NoDoubleActivateDirective,
  InputOnlyNumbersDirective,
  TooltipDirective,
  FocusFirstFocusableElementDirective,
  TabsDirective,
  FormElementStateDirective,
  CloseActionMenuDirective,
  DownloadLinkDirective,
  TranslatedLinksDirective,
  TranslateTemplatesDirective,
  CtrlKeyDirective,
  TreeComponent,
  AlertComponent,
  BannerComponent,
  FormErrorMessagesComponent,
  ErrorMessageComponent,
  NotificationsComponent,
  DownloadReportNewComponent,
  ExportReportButtonComponent,
  CropImageComponent,
  TooltipComponent,
  TypeaheadComponent,
  CopyToClipboardComponent,
  ScanActionsComponent,
  RuleSeverityComponent,
  CustomRuleSeverityComponent,
  RuleFilterComponent,
  ...pipes,
  HighlightDirective,
  HasPermissionsDirective,
  AriaSortDirective,
  AriaLabelSortDirective,
  ViewportBoxComponent,
  ViewportInlineComponent,
  PaginationEntriesPerPageComponent,
  PaginationArrowsNavigationComponent,
  FilteringInputComponent,
  DatetimeLocalComponent,
  DateFieldComponent,
  CustomTableComponent,
  TabsComponent,
  DigitalPropertyWorkspaceFilterComponent,
  TableEmptyStateComponent,
  ScanScoreCircleComponent,
  ScoreChartComponent,
  CommonSwitchComponent,
  CommonNameTagComponent,
  CommonCheckboxComponent,
  CommonSelectComponent,
  CommonInfoNoteComponent,
  AccordionComponent,
  AccordionChevronComponent,
  AccordionItemComponent,
  SortSelectorComponent,
  ViewportCustomValuesComponent,
  LabelValueComponent,
  ScanAuthenticationDetailsComponent,
  ScanPreScanFlowComponent,
  DomainRestrictionsComponent,
  ScanExtraHeadersComponent,
  MonitoringAlertIconComponent,
  MonitoringLabelComponent,
  RuleStatusToggleComponent,
  DeprecatedScanTagSelectorComponent,
  ScanTagSelectorComponent,
  FilterKeywordComponent,
  FilterCheckboxComponent,
  FilterNumberComponent,
  FilterSelectComponent,
  FilterHostDirective,
  ScanTagFilterComponent,
  StatusComponent,
  AvatarInputComponent,
  EditUserComponent,
  EditPermissionsBannerComponent,
  AdminPortalPermissionsTableComponent,
  OrganizationPermissionsTableComponent,
  WorkspacePermissionsTableComponent,
  DigitalPropertyPermissionsTableComponent,
  ViewSingularRuleHistoryComponent,
  ViewRuleHistoryItemComponent,
  ViewFlawStatusHistoryComponent,
  ViewFlawStatusHistoryItemComponent,
  RuleIssuesTableComponent,
  RuleIssuesSupplementaryTableComponent,
  ScanHealthChartComponent,
  ScanAnalyticsHealthGraphComponent,
  MonitoringTrendModalComponent,
  CommonCommentComponent,
  UpsertTaskModalComponent,
  UpsertTaskSlideoutComponent,
  CreateProjectSlideoutComponent,
  DashboardUserPreferencesSlideoutComponent,
  CommonConfirmationComponent,
  ...tableComponents,
  ...stepper,
  MarkdownViewComponent,
  AiChatMarkdownViewComponent,
  ViewAvatarComponent,
  IssuesViewDetailsComponent,
  PageLoadingStateComponent,
  AttachmentThumbnailComponent,
  EditAttachmentModalComponent,
  CheckboxConfirmationModalComponent,
  PromptConfirmationModalComponent,
  AlertModalComponent,
  ShareIssueReportModalComponent,
  ShareIssueReportUrlComponent,
  AdvancedSearchComponent,
  ResponsivePaginationComponent,
  SvgIconsComponent,
  SecureResourceIdDirective,
  GlobalSpinnerComponent,
  WorkspaceUsersListComponent,
  TableColumnSelectorComponent,
  TableColumnSelectorPanelComponent,
  BreadcrumbWrapperComponent,
  RouteWrapperComponent,
  FormFieldLineCountComponent,
  ViewportSelectComponent,
  MonitoringScopeStepComponent,
  MonitoringScheduleStepComponent,
  MonitoringScanSettingsStepComponent,
  MonitoringAlertsStepComponent,
  MonitoringAlertsEditorComponent,
  MonitoringReviewStepComponent,
  HandlePopupsComponent,
  WaitTimeComponent,
  RiskScoreExplanationModalComponent,
  RiskManagementStatusStepperComponent,
  WebsitesAppsByRiskManagementStatusComponent,
  WebsitesAppsByRiskManagementStatusChartComponent,
  WebsitesAppsByRiskManagementStatusTableModalComponent,
  WebsitesAppsByRiskManagementStatusDetailsComponent,
  TypeaheadDirective,
  FocusDirective,
  ImageHighlighterComponent,
  CommonAlertBoxComponent,
  CommonMultiSelectComponent,
  CommonTrendComponent,
  AccordionPanelHeaderDirective,
  AccordionPanelContentDirective,
  AccordionPanelDirective,
  AccordionPanelToggleDirective,
  AccordionButtonDirective,
  UserMfaComponent,
  InfoModalComponent,
  CommonSpinnerComponent,
  CommonCheckboxTreeComponent,
  CheckboxTreeNodeComponent,
  ...formComponents,
  TestingToolFilterComponent,
  TurnOffMonitoringConfirmationComponent,
  MonitoringQuickSetupComponent,
  ExcludePagesComponent,
  EapIconsComponent,
  IconComponent,
  TenantSelectorComponent,
  MonitoringAlertTableComponent,
  ResolvedFindingsProgressComponent,
  OverdueUnresolvedFindingsBySeverityComponent,
  ProgressDoughnutComponent,
  NgbNavDirective,
  TrapFocusDirective,
  AccordionFilterComponent,
  FeatureFlagDirective,
  AccessibilityConformanceLevelSelectorComponent,
  CodeBlockComponent,
  SecureAlertComponent,
  NewNavCardDirective,
  ConnectionErrorModalComponent,
  DisconnectedTasksModalComponent,
  AddPageModalComponent,
  ToolTemplateComponent,
  AuditRuleTableComponent,
  AuditRuleViewComponent,
  UpsertAuditRuleFormComponent,
  UpsertAuditRuleComponent,
  AuditDeleteRuleModalContentComponent,
  DsConfirmationModalComponent,
  ToggleSwitchComponent,
];

@NgModule({
  imports: [
    DsModule,
    RouterModule,
    CommonModule,
    FormsModule.withConfig({ callSetDisabledState: 'whenDisabledForLegacyCode' }),
    ReactiveFormsModule.withConfig({ callSetDisabledState: 'whenDisabledForLegacyCode' }),
    NgbModule,
    CommonPipesModule,
  ],
  declarations: [...sharedComponents],
  providers: [NgbActiveModal],
  exports: [
    ...sharedComponents,
    RouterModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    DsModule,
    CommonPipesModule,
  ],
})
export class SharedModule {}
