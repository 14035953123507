import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { FeatureFlagService } from '../../services/feature-flag/feature-flag.service';

/**
 * Primarily used by e2es to know when feature flags are initialized/authorized
 */
@Component({
  selector: 'app-feature-flag-initialized',
  templateUrl: './feature-flag-initialized.component.html',
})
export class FeatureFlagInitializedComponent {
  public authorized$: Observable<boolean>;
  public initialized$: Observable<boolean>;
  constructor(private featureFlagService: FeatureFlagService) {
    this.authorized$ = this.featureFlagService.isAuthorizedClientInitialized$;
    this.initialized$ = this.featureFlagService.isClientInitialized$;
  }
}
