import { Injectable } from '@angular/core';
import { uniqBy } from 'lodash';

import { AuditStandards } from '../../../shared/constants/audit-standard';
import {
  ISuccessCriteria,
  ISuccessCriteriaIndex,
} from '../../../shared/audits/definitions/success-criteria/success-criteria.interface';
import { Legacy } from '../../../shared/audits/definitions/success-criteria/legacy';
import { Wcag } from '../../../shared/audits/definitions/success-criteria/wcag';
import { Axe } from '../../../shared/audits/definitions/success-criteria/axe';
import { Aslint } from '../../../shared/audits/definitions/success-criteria/aslint';
import { Wave } from '../../../shared/audits/definitions/success-criteria/wave';
import { NotApplicable } from '../../../shared/audits/definitions/success-criteria/notApplicable';
import { WcagCr } from '../../../shared/audits/definitions/success-criteria/wcag-cr';
import { EN301549 } from '../../../shared/audits/definitions/success-criteria/en-301-549';
import { Section508 } from '../../../shared/audits/definitions/success-criteria/section-508';
import { SharedCommonUtility } from '../../../shared/utils/common.utility';
import { $successCriteria } from '../../../shared/audits/definitions/success-criteria/constants';

@Injectable({
  providedIn: 'root',
})
export class SuccessCriteriaService {
  private readonly legacySuccessCriteria: ISuccessCriteriaIndex;
  private standardToSuccessCriteriaIndex: Record<AuditStandards, ISuccessCriteriaIndex>;

  constructor() {
    this.legacySuccessCriteria = new Legacy();
    this.standardToSuccessCriteriaIndex = {
      [AuditStandards.wcag]: new Wcag(),
      [AuditStandards.wcagCr]: new WcagCr(),
      [AuditStandards.axe]: new Axe(),
      [AuditStandards.essential]: new Aslint(),
      [AuditStandards.wave]: new Wave(),
      [AuditStandards.en301549]: new EN301549(),
      [AuditStandards.section508]: new Section508(),
      [AuditStandards.css]: this.legacySuccessCriteria,
      [AuditStandards.html]: this.legacySuccessCriteria,
      [AuditStandards.notApplicable]: new NotApplicable(),
    };
  }

  public getSuccessCriteriaFromStandard(standardIdentifier: AuditStandards, identifier: string): ISuccessCriteria | null {
    return this.standardToSuccessCriteriaIndex[standardIdentifier].get(identifier);
  }

  public getSuccessCriteriaListFromStandards(
    identifiers: string[],
    standards: AuditStandards[] = [AuditStandards.wcag],
  ): ISuccessCriteria[] {
    return identifiers
      .map((identifier: string): ISuccessCriteria | null => {
        if (identifier === AuditStandards.notApplicable) {
          return this.getSuccessCriteriaFromStandard(AuditStandards.notApplicable, identifier);
        }

        for (const standard of standards) {
          const successCriteria: ISuccessCriteria = this.getSuccessCriteriaFromStandard(standard, identifier);
          if (SharedCommonUtility.notNullish(successCriteria)) {
            return successCriteria;
          }
        }

        return null;
      })
      .filter(SharedCommonUtility.notNullish);
  }

  public getAllSuccessCriteriaFromStandard(standardIdentifier: AuditStandards): ISuccessCriteria[] {
    return this.standardToSuccessCriteriaIndex[standardIdentifier].getAll();
  }

  /**
   * Gets all WCAG and Not applicable success criterias in raw format.
   */
  public getAllWCAGWithNACriteriaFromStandard(): ISuccessCriteria[] {
    return [
      ...this.getAllSuccessCriteriaFromStandard(AuditStandards.wcag),
      ...this.getAllSuccessCriteriaFromStandard(AuditStandards.notApplicable),
    ].filter((criteria: ISuccessCriteria | null): boolean => criteria !== null);
  }

  /**
   * Gets all WCAG, non-WCAG and Not applicable success criterias in raw format.
   */
  public getAllSupportedCriteriaFromStandard(): ISuccessCriteria[] {
    const result: ISuccessCriteria[] = [
      ...this.getAllSuccessCriteriaFromStandard(AuditStandards.wcag),
      ...this.getAllSuccessCriteriaFromStandard(AuditStandards.wcagCr),
      ...this.getAllSuccessCriteriaFromStandard(AuditStandards.en301549),
      ...this.getAllSuccessCriteriaFromStandard(AuditStandards.section508),
      ...this.getAllSuccessCriteriaFromStandard(AuditStandards.notApplicable),
    ].filter((criteria: ISuccessCriteria | null): boolean => criteria !== null);
    return uniqBy(result, $successCriteria.num);
  }
}
